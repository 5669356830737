html {
    font-size: 100%
}

* {
    box-sizing: border-box;
}

:root {
    --sgroesse: 0.9em;
    --abst_gl_v: 2px;
    --width_gl_button: 80px;
    --sg_normal: 1em;
    /* normale Schriftgröße */
}

body {
    /* remove margins and scroll bars */
    display: none;
    margin: 0px;
    overflow: auto;
    overscroll-behavior: none;
    /* style text */
    text-align: left;
    font-size: var(--sg_normal);
    /*12px;  */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, Verdana, Arial, Helvetica, sans-serif;

    /* color text */
    color: #444;
    background-color: #dddedf;

}

a:link {
    /*color: black;
    background-color: transparent; */
    text-decoration: none;
}

/* Style the header */
.header {
    grid-area: header;
    background-color: #f1f1f1;
    padding: 0;
    margin: 0;
    /*padding: 30px;
    text-align: center;
    font-size: 35px; */
}

/* The grid container */
.grid-container {
    display: grid;
    /*width: min(100%, 2500px);*/
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    grid-template-areas:
        'header header'
        'topLeft topRight'
        'unten_grafik unten_grafik'
        'unten_webgl unten_webgl'
        'footer footer';
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media (max-width: 770px) {
    .grid-container {
        display: grid;
        margin: 0;
        padding: 0;
        grid-template-areas:
            'header'
            'topRight'
            'topLeft'
            'unten_grafik'
            'unten_webgl'
            'footer';
    }
}


.topLeft,
.topRight,
.left,
.middle,
.right {
    padding: 0px;
    margin: 0;
}

/* Style the topLeft column */
.topLeft {
    grid-area: topLeft;
    padding: 0px;
    margin: 0px;
    background-color: #D8D8D8;
    /*background-color: #c42929;*/
}

/* Style the topRight column */
.topRight {
    grid-area: topRight;
    padding: 0px;
    /*padding-left: 5px;*/
    margin: 0px;
    background-color: #D8D8D8;
    /*background-color: #83c429;*/
}

/* Style the left column */
.left {
    grid-area: left;
}

/* Style the middle column */
.middle {
    grid-area: middle;
}

/* Style the right column */
.right {
    grid-area: right;
}

/*
.rand {
    grid-area: rand;
    background-color: #f5f5f5;
}

.rand1 {
    grid-area: rand1;
}
*/
.unten_grafik {
    grid-area: unten_grafik;
    padding: 0;
    margin: 0;
}

.unten_webgl {
    grid-area: unten_webgl;
    padding: 0;
    margin: 0;
    /*background-color: #d60000;*/
}

/* Style the footer */
.footer {
    grid-area: footer;
    background-color: #f1f1f1;
    margin: 0;
    padding: 0;
    /*padding: auto;
    margin: auto;*/
    text-align: center;
    font-size: 0.7em;
    /*0.8rem;*/
    /*height: 50px;*/
    /*0.7rem*/
}


/* Responsive layout -  */
/*
@media (max-width: 1000px) {
    .grid-container {
        grid-template-areas:
    'rand header header header header header rand'
    'rand topLeft topLeft topRight topRight topRight rand'
    'rand left left left middle right rand'
    'rand footer footer footer footer footer rand';

    }
}
*/
#titel {
    width: 50%;
    background-color: #FFFFD0;
    padding: 8px;
    border: 4px solid darkred;
    border-radius: 8px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
}

/*
#satz {
    color: red;
}

hr {
    display: block;
    width: 50%;
    margin-left: 0;
    margin-right: 0;
    border-width: 2px;
}
*/
.topnav {
    overflow: hidden;
    background-color: #303030;
}

.topnav a {
    float: left;
    display: block;
    color: #F8F8F8;
    text-align: center;
    padding: 8px;
    margin: 0;
    text-decoration: none;
    font-size: 1.125em;

}

.topnav a:hover {
    background-color: #D8D8D8;
    color: darkblue;
    margin: 0;
}

.topnav a:focus {
    background-color: #D8D8D8;
    color: darkblue;
    border-style: solid solid none none;
    border-color: #D8D8D8;
    border-radius: 0px 10px 0px 0px;
    margin: 0;
}

/*
.topnav a.active {
     wozu ?
    background-color: #4CAF50;
    color: green;
}

.topnav .info {
    display: none;
}
*/
td,
th {
    padding: 0px;
    margin: 0px;
    width: 6em;
    /*width: 70px;*/
    /*background-color: orange;*/
}


p {
    font-size: 1em;
    line-height: 200%
}

textarea {
    font-size: 1em;
    font-family: FreeSans, Verdana, Arial, Helvetica, sans-serif;
}

#eingabe {
    display: none;
    /*position: absolute;
    top: 60px;
    left: 0px;
    width: 100%;*/
    background-color: rgb(110, 180, 180);
}


#ergebnisse {
    display: none;
    background-color: #D8D8D8;
    padding: 5px;
}

#knotentabelle {
    /*display: none;*/
    background-color: #D8D8D8;
    /*z-index: 190;*/
}

#eingabe_duennQ_top {
    display: none;
    background-color: #D8D8D8;
    padding: 0;
    padding-left: 5px;
}

#duennQ_save_daten {
    display: none;
    background-color: #D8D8D8;
    margin: 0;
    padding: 0;
    padding-left: 5px;
}

#elementtabelle {
    /*display: none;*/
    background-color: #D8D8D8;
}

/*
#querschnittswerte {
    display: none;
}
*/
button,
select {
    font-size: 1em;
    /*1rem;*/
    border-radius: 3px;
    border-width: 1px;
    border-color: #303030;
    color: #444;
    padding: 0.2em;
}

button:active {
    background-color: darkorange;
}

.buttonTable {
    /*font-size: 1rem;*/
    width: 10em;
}

.buttonTable:disabled {
    /*font-size: 1rem;*/
    color: rgb(199, 199, 199);
    border-color: rgb(199, 199, 199);
}

input,
label {
    font-size: 1em;
    /*1rem;*/
}

.zelle {
    width: 3.125em;
}

.zelle0 {
    /*width: 4em;*/
    text-align: right;
    white-space: nowrap;
    /*font-size: 1rem;*/
}

.input_kdtab {
    width: 5em;
    /*80px;*/
}


#id_einstellungen {
    padding: 0;
    margin: 0;
    padding-left: 5px;
    background-color: #D8D8D8;
    /*background-color: darkkhaki;*/
}


td.selected {
    background-color: green;
}

.tabelle {
    background-color: rgb(150, 180, 180);
}

#my_dataviz {
    display: none;
}

.table_eng th,
td {
    border: none;
    /*1px solid black*/
    /*border-collapse: collapse;*/
    padding: 1px;
    width: 5.625em;
}

.td_eng {
    padding: 1px;
    width: 3em;
}

.td_eng_right {
    text-align: right;
    padding: 1px;
    width: 3em;
}

#querschnittwerte_table td {
    padding-left: 5px;
    padding-right: 5px;
    background-color: rgb(200, 200, 200);
    width: 7.5em;
}

.querschnittwerte_table_z0 {
    text-align: right;
}


.table_spannung_cell_center {
    text-align: center;
}

.table_spannung_cell_center1 {
    text-align: center;
    background-color: lightgrey;
}

#my-svg {
    position: relative;
    display: none;
    width: inherit;
    /*1000px;*/
    height: inherit;
    padding: 0;
    margin: 0;
    /*1000px;*/
    overflow: hidden;
    background-color: rgb(142, 156, 167);
}

#cursor_coordy {
    /*display: inline-block;*/
    position: absolute;
    top: 10px;
    left: 10px;
    width: 4em;
    height: 25px;
    background-color: rgb(220, 220, 220);
    padding: 3px;
    margin-top: 0;
    border-top: 0;
    border-style: none;
    font-size: var(--sgroesse);
    display: flex;
    align-items: center;
    z-index: 100;
    /*justify-content: center;*/
}

#cursor_coordz {
    /*display: inline-block;*/
    position: absolute;
    top: 10px;
    /*-15*/
    left: calc(10px + 4em);
    width: 4em;
    height: 25px;
    background-color: rgb(220, 220, 220);
    padding: 3px;
    margin-top: 0;
    border-style: none;
    border-top: 0;
    font-size: var(--sgroesse);
    display: flex;
    align-items: center;
    z-index: 100;
    /*justify-content: center;}*/
}

#button_label_svg {
    position: absolute;
    top: 10px;
    /*-15*/
    left: calc(10px + 10em);
}

#button_copy_svg {
    position: absolute;
    top: 10px;
    /*-15*/
    left: calc(10px + 15em);
}

#circleBasicTooltip,
#circleTooltip_SM {
    position: absolute;
    /*z-index: 110;*/
}

#dataviz_area {
    /*position: relative;*/
    /*top: -50px;*/
    background-color: #8e9ca7;
    margin: 0;
    padding: 0;
    width: inherit;
    height: inherit;
    overflow: hidden;
}

#panel_gui {
    position: absolute;
    top: var(--abst_gl_v);

    left: 2px;
    z-index: 90;
    margin: 0;
    padding: 0;
}

#my-webgl {
    position: relative;
    margin: 0px;
    padding: 0px;
    /*background-color: #bbb*/
    background-color: #D8D8D8;
    /*background-color: blueviolet;*/
}

#unit_webgl {
    position: absolute;
    top: 1em;
    left: calc(100% - 14em);
    width: 14em;
    text-align: left;
    z-index: 80;
    /*display: block;*/
    padding: 0;
    margin: 0px;
    user-select: none;

}

#maxValues_webgl {
    position: absolute;
    top: 3em;
    left: calc(100% - 14em);
    width: 14em;
    text-align: left;
    z-index: 80;
    /*display: block;*/
    padding: 0;
    margin: 0px;
    user-select: none;
}

.emotionLabel {
    color: #02024f;
    /* #d60000 */
    /* font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif; */
    margin-top: 0px;
    /*-5px 0 0 15px;*/
    margin-left: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    border: 1px solid rgb(85, 82, 82);
    padding: 2px;
    border-radius: 2px;
    font-size: 1em;
    /*1rem;*/
    z-index: 120;
}

.c_hilfe {
    /*text-align: left;*/
}

#info_berechnung {
    text-align: right;
}


#infoBox {
    padding-left: 5px;
}



input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

#id_myTable,
#nodeTable,
#elemTable {
    border: none;
    border-spacing: 0px;
    padding: 5px;
    margin: 5px;
    background-color: rgb(207, 217, 21);
    border-radius: 5px;
}

#id_myTable td,
#nodeTable td,
#elemTable td {
    /*font-size: 1rem;*/
    /*touch-action: none;*/
}

#id_myTable td.selected,
#nodeTable td.selected,
#elemTable td.selected {
    /*background-color: rgb(206, 196, 46);*/
    color: rgb(13, 13, 13);

}

#id_myTable td.highlight,
#nodeTable td.highlight,
#elemTable td.highlight {
    background-color: orange;
    color: darkslateblue;

}

.input_select {
    background-color: orange;
    color: darkslateblue;
    /*touch-action: none;*/
}

.input_normal {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    /*font-variant-numeric: oldstyle-nums tabular-nums;
    touch-action: none;*/
}

#id_display_einstellungen {
    float: right;
    color: #cec42e;
}

#unitLength {
    width: 50pt;
}

.unit_stress {
    /*font-size: var(--sg_normal);*/
    text-align: left;
    z-index: 100;
}

.input_int {
    width: 3.125em;
    margin: 0;
    padding: 1px;
    border-top: 1px solid #444;
    border-bottom: 1px solid #444;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    text-align: center;
}

.spinner {
    border: solid 1px #444;
    margin: 0;
    padding: 1px;
    width: 1em;
    /*vertical-align: middle;
    background-color: lightslategrey */
}

.spinner:hover {
    background: lightgrey;
}

.decrement {
    /*.decrement*/
    border-radius: 3px 0px 0px 3px;
    color: #000000;
}

.increment {
    /*.increment*/
    border-radius: 0px 3px 3px 0px;
    color: #000000;

}


.lil-gui {
    --font-size: 14px;
    --font-family: system-ui,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        'Open Sans',
        'Helvetica Neue',
        sans-serif,
        Verdana,
        Arial,
        Helvetica,
        sans-serif;
    /*--input-font-size: 16px;
    --padding: 0.125em;
    --spacing: 4px;
    */
    --name-width: 60%;
    --slider-input-width: 1%;
    /* Zahleneingabe */
    --slider-input-min-width: 30px;
    /*--background-color: #1f1f1f47;*/
    --title-background-color: #B3AE00;
    /* #8e9ca7;
     #36739e;*/
    --title-text-color: #fefffa;
    /*--focus-color: #33d17a;*/
}


.lil-gui.allow-touch-styles {
    --widget-height: 28px;
    --padding: 2px;
    --spacing: 2px;
    --font-size: 14px;
    --input-font-size: 14px;
}